import React from 'react'

import Breadcrumb from 'src/components/Breadcrumb'

type BreadcrumbItem = {
  link: string
  text: string
}

type BreadCrumb = {
  path: BreadcrumbItem[]
}

type PageTitleProperties = {
  breadcrumb: BreadCrumb
  pageTitle: string
  pageSubTitle?: string
  hasLine?: boolean
}

const PageTitle = ({ breadcrumb, pageTitle, pageSubTitle, hasLine }: PageTitleProperties) => (
  <div className="col-12">
    <div className="d-none d-md-block mt-4">
      <Breadcrumb path={breadcrumb.path} />
    </div>
    <div className="d-flex align-items-center">
      <h1
        className={`fs-24 lh-30 fw-600 mt-4 ${
          hasLine ? 'mb-0' : 'mb-4'
        } mt-md-3 text-grayscale--500`}
        dangerouslySetInnerHTML={{ __html: pageTitle }}
      />
    </div>

    {pageSubTitle && (
      <p
        className={`fs-18 lh-22 text-grayscale--500 ${hasLine && 'mb-0'}`}
        dangerouslySetInnerHTML={{ __html: pageSubTitle }}
      />
    )}
    {hasLine && <hr className="mb-5" />}
  </div>
)

export default PageTitle
