import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import DefaultCarousel from 'src/components/DefaultCarousel'
import CategoryHeader from 'src/components/CategoryHeader'

import OrangeIcon from '../Icon/OrangeDsIcon'
import { SectionWrapper, BlogCarousel, CardVideo, Player } from './style'

type DataProps = {
  title: string
  image: string
  alt: string
  link: string
  slug: string
}

type dataProps = {
  news: DataProps[]
  title: string
  categoryName: string
  color: string
}

type InterPortfolioProp = {
  data: dataProps
}

function OInterPortifolio({ data }: InterPortfolioProp) {
  const [sendDatalayerEvent] = useDataLayer()
  return (
    <SectionWrapper id="dicas-para-investir-melhor" className="bg-white d-flex py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-4">
            <CategoryHeader category={data.title} />
          </div>

          <div className="col-12">
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 3 }}
              lg={{ items: 3 }}
              xl={{ items: 3 }}
              containerClass="carrossel"
              itemClass=""
            >
              {data.news.map((item: DataProps) => (
                <BlogCarousel key={item.title}>
                  <a
                    href={`/aprenda-a-investir/${item.slug}`}
                    title={item.title}
                    onClick={() => {
                      sendDatalayerEvent({
                        section: item.title,
                        element_action: 'click button',
                        element_name: item.title,
                        redirect_url: `/aprenda-a-investir/${item.slug}`,
                      })
                    }}
                  >
                    <CardVideo bg={item.image} role="img" aria-label={item.alt}>
                      <Player>
                        <OrangeIcon size="MD" icon="play" color="#ffffff" />
                      </Player>

                      <figcaption>
                        <h3 className="fs-16 lh-20 fs-lg-20 lh-lg-25 fw-600 text-white">
                          {item.title}
                        </h3>
                      </figcaption>
                    </CardVideo>
                  </a>
                </BlogCarousel>
              ))}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}
export default OInterPortifolio
