import styled, { css } from 'styled-components'
import { grayscale, white } from 'src/styles/colors'

import breakpoints from '../../../styles/breakpoints'

const modifiers = {
  right: () => css`
    right: 35px;
  `,
  left: () => css`
    left: 0px;
  `,
}

export const TabGroup = styled.ul`
  list-style: none;
  display: inline-flex;
  padding: 0;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #dedfe4;
  width: 100%;
`

type TabItemStyleProps = {
  active: boolean
}

export const TabItem = styled.li<TabItemStyleProps>`
  ${({ theme }) => css`
    flex: 0 0 auto;

    .tab-item,
    > a {
      border: none;
      border-bottom: 3px solid ${grayscale[100]};
      background: transparent;
      padding: 10px 20px;
      font-weight: 600;
      font-size: 14px;
      color: ${grayscale[400]};
      min-width: 100px;
      position: relative;
      text-decoration: none;
      cursor: pointer;

      ${(props: TabItemStyleProps) =>
        props.active &&
        css`
          color: ${theme.colors.primary};

          &::after {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: ${theme.colors.primary};
            border-radius: 5px 5px 0 0;
          }
        `}

      &:focus {
        outline: 0px;
      }
    }
  `}
`

type TabScrollButtonStyleProps = {
  direction: string
}

export const TabScrollButton = styled.button<TabScrollButtonStyleProps>`
  position: absolute;
  top: 8px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none !important;

  ${(props: TabScrollButtonStyleProps) => modifiers[props.direction]}

  svg {
    fill: ${grayscale[300]};
  }
`

export const SearchButton = styled.button`
  border: none;
  background: ${white};
  cursor: pointer;
  height: 35px;
  width: 35px;
  flex: 0 0 auto;
  padding: 0;
  position: absolute;
  right: 0;
  top: -2px;

  @media (min-width: ${breakpoints.xl}) {
    right: -3px;
  }

  &:focus {
    outline: 0px;
  }

  img {
    width: 24px;
    height: 24px;
  }
`
