import styled from 'styled-components'
import { grayscale, error, success } from 'src/styles/colors'
import { borderRounded } from 'src/styles/radius'
import breakpoints from 'src/styles/breakpoints'

export const Container = styled.div`
  border: 1px solid ${grayscale['200']};
  border-radius: ${borderRounded['12']};
  font-family: 'Sora';
  min-height: 353px;
  padding: 24px;

  @media (min-width: ${breakpoints.md}) {
    padding: 16px;
  }

  .border-list {
    border-bottom: 1px solid ${grayscale['100']};
    margin-bottom: 16px;
    padding-bottom: 16px;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .neutral {
    color: ${grayscale[400]};
  }

  .success {
    color: ${success};
  }

  .error {
    color: ${error};
  }
`
