import styled from 'styled-components'
import { grayscale, orange } from 'src/styles/colors'

export default styled.nav`
  span {
    a {
      opacity: 1;
      font-weight: 600;
      color: ${(props) => props.theme.colors.primary};

      &:hover {
        color: ${orange.base};
      }

      &:last-child {
        font-weight: 400;
        color: ${grayscale['400']};
      }
    }

    svg {
      margin: 0 5px;
    }
  }
`
