import React from 'react'

import OrangeDsIcon from 'src/components/Icon/OrangeDsIcon'
import { ArrowProps } from 'react-multi-carousel'

type ColorProp = {
  color: string
}

function LeftArrow({ onClick, color }: ArrowProps & ColorProp) {
  return (
    <button
      onClick={onClick}
      type="button"
      className="border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
    >
      <OrangeDsIcon size="LD" color={color} icon="arrow-left" />
    </button>
  )
}

export default LeftArrow
