import React from 'react'

import { PublishedAndReadingTimeWrapper } from './style'
import OrangeIcon from '../Icon/OrangeDsIcon'

type PublishedAndReadingTimeModel = {
  dataPublicacao: string
  tempoLeitura: string
  color?: string
  font?: string
}

const PublishedAndReadingTime = ({
  dataPublicacao,
  tempoLeitura,
  color = '#6a6c72',
  font = 'sora',
}: PublishedAndReadingTimeModel) => (
  <PublishedAndReadingTimeWrapper>
    <div className="d-flex">
      <OrangeIcon icon="agenda" size="SM" color={color} />
      <span className={`fs-12 ${font} text-grayscale--400 lh-15 mr-3 ml-2`}>
        Publicado {dataPublicacao}
      </span>
      {tempoLeitura !== '' && (
        <>
          <OrangeIcon icon="pending" size="SM" color={color} />
          <span className={`fs-12 ${font} text-grayscale--400 lh-15 ml-2`}>
            {tempoLeitura} min de leitura
          </span>
        </>
      )}
    </div>
  </PublishedAndReadingTimeWrapper>
)

export default PublishedAndReadingTime
