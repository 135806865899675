import React, { useEffect } from 'react'

// import { PageProps } from 'gatsby'
// Components
import DefaultCarousel from 'src/components/DefaultCarousel'
import QuotesEconomyIndices from 'src/components/QuotesEconomyIndices'
// import Grid from 'src/components/Grid'
import IconOrangeDs from 'src/components/Icon/OrangeDsIcon'
// import SpotlightFilessSmall from 'src/components/SpotlightFilesSmall'
// import categoriesJSON from 'src/assets/data/categories.json'
// import filesPackges from 'src/assets/data/filePackges.json'
// import simuladores from 'src/assets/data/simuladores'
import { useTheme } from 'styled-components'

// import CurrencyQuote from '../../../components/QuotesEconomyIndices/currencyQuote'

export type Article = {
  id: string
  slug: string
  title: string
  introduction: string
  gridImageUrl: string
  categoryName: string
  publishedAt: string
  updatedAt: string
  shares: number
  spotlight: boolean
  readingTime: number
}

// type CategoryProps = {
//   pageContext: {
//     pageTitle: string
//     categoria: string
//     allArticles: Article[]
//     categoryid: string
//     pageSubTitle: string
//   }
// } & PageProps

// const bannerFill = {
//   showCategoryHeader: false,
//   template: 'D',
//   dados: simuladores.dados,
//   removeGradient: true,
// }

// const Ferramentas = ({ pageContext }: CategoryProps)

const Ferramentas = () => {
  const theme = useTheme()
  // const category = categoriesJSON.find(
  //   (categoryItem) => categoryItem.slug === pageContext.categoria,
  // )
  // const file = filesPackges

  const HandleScroll = (id: string) => {
    const section = document.getElementById(id)
    const position = section?.offsetTop || 0
    const poss = position - 187
    window.scroll({
      top: poss,
      behavior: 'smooth',
    })
  }

  const clearLocation = (location: string) => {
    const url = new URL(location)
    const id = url.searchParams.get('id')
    return id
  }

  useEffect(() => {
    const location = clearLocation(window.location.href)

    if (location) {
      setTimeout(() => HandleScroll(location), 100)
    }
  }, [])

  return (
    <>
      <section className="py-4 pb-mb-0" id="bolsa">
        <div className="container">
          <div className="mb-5">
            <div className="d-flex align-items-center mb-3">
              <div className="mr-2">
                <IconOrangeDs size="MD" color={theme.colors.primary} icon="investments" />
              </div>
              <h2 className="fs-20 lh-25 fs-md-24 lh-md-30 fw-600 m-0 text-grayscale--500">
                Bolsa
              </h2>
            </div>
            <p className="fs-16 lh-19 fs-md-18 lh-md-22 fw-400 text-grascale--500">
              Acompanhe os movimentos da Bolsa
            </p>
          </div>
          <div className="row">
            <div className="col-12 px-0 reset-carousel-quotes">
              <DefaultCarousel
                sm={{ items: 1 }}
                md={{ items: 3 }}
                lg={{ items: 3 }}
                xl={{ items: 3 }}
                color={theme.colors.primary}
              >
                <QuotesEconomyIndices title="Maiores Altas" />
                <QuotesEconomyIndices title="Maiores Baixas" />
                {/* <QuotesEconomyIndices title="Recomendados Inter Research" /> */}
              </DefaultCarousel>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="py-4 pb-mb-0" id="cambio">
        <div className="container">
          <div className="mb-5">
            <div className="d-flex align-items-center mb-3">
              <div className="mr-2">
                <IconOrangeDs size="MD" color={theme.colors.primary} icon="exchange" />
              </div>
              <h2 className="fs-20 lh-25 fs-md-24 lh-md-30 fw-600 m-0 text-grayscale--500">
                Câmbio
              </h2>
            </div>
            <p className="fs-16 lh-19 fs-md-18 lh-md-22 fw-400 text-grascale--500">
              O sobe e desce das principais moedas
            </p>
          </div>
          <div className="row">
            <div className="col-12 px-0 reset-carousel-quotes">
              <div className="col-12 col-md-4 px-0 reset-carousel-quotes">
                <CurrencyQuote title="Câmbio" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="py-4 pb-mb-0" id="indices">
        <div className="container">
          <div className="mb-5">
            <div className="d-flex align-items-center mb-3">
              <div className="mr-2">
                <IconOrangeDs size="MD" color={theme.colors.primary} icon="inter-research" />
              </div>
              <h2 className="fs-20 lh-25 fs-md-24 lh-md-30 fw-600 m-0 text-grayscale--500">
                Índices
              </h2>
            </div>
            <p className="fs-16 lh-19 fs-md-18 lh-md-22 fw-400 text-grascale--500">
              O sobe e desce das principais moedas
            </p>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 px-0 reset-carousel-quotes">
              <CurrencyQuote title="Gerais" />
            </div>
            <div className="col-12 col-md-6 px-0 reset-carousel-quotes">
              <CurrencyQuote title="Commodities" />
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="py-5">
        <div className="container">
          <div className="d-flex align-items-center mb-3 justify-content-between">
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <IconOrangeDs size="MD" color={theme.colors.primary} icon={simuladores.icon} />
              </div>
              <h2 className="fs-20 lh-25 fs-md-24 lh-md-30 fw-600 m-0 text-grayscale--500">
                {simuladores.title}
              </h2>
            </div>
            <a className="fs-14 lh-18 fw-700" href={simuladores.link} title={category}>
              Exibir tudo
              <IconOrangeDs
                size="MD"
                icon="arrow-right"
                color={theme.colors.primary}
                className="ml-2"
              />
            </a>
          </div>
          {simuladores.paragraph ? (
            <p className="fs-16 lh-19 fs-md-18 lh-md-22 fw-400 text-grascale--500">
              {simuladores.paragraph}
            </p>
          ) : null}

          <Grid {...bannerFill} />
        </div>
      </section> */}
      {/* {file && file.files.length > 0 && (
        <section className="py-5 pb-mb-0">
          <div className="container">
            <SpotlightFilessSmall data={{ ...file, category: pageContext.categoria }} />
          </div>
        </section>
      )} */}
    </>
  )
}

export default Ferramentas
