import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

type SectionProps = {
  isHome?: boolean
}

export const Section = styled.section`
  display: block;
  justify-content: center;
  max-width: 100%;
  .dots {
    position: static;
    margin-top: 42px;
    li button {
      border: 2px solid #ff7a00;
    }
    .react-multi-carousel-dot--active button {
      background: #ff7a00;
    }
  }
  .container {
    @media (min-width: 769px) {
      max-width: 100%;
      margin: none;
      padding: 0;
    }
  }
  .react-multi-carousel-track {
    padding-left: ${(props: SectionProps) => (props.isHome ? '40px' : '75px')};

    @media (min-width: ${breakpoints.md}) {
      padding-left: ${(props: SectionProps) => (props.isHome ? '108px' : '125px')};
    }
    @media (min-width: ${breakpoints.lg}) {
      padding-left: ${(props: SectionProps) => (props.isHome ? '115px' : '125px')};
    }
    @media (min-width: ${breakpoints.xl}) {
      padding-left: ${(props: SectionProps) => (props.isHome ? '247px' : '125px')};
    }
  }
`
export const Figure = styled.figure`
  position: relative;
  height: 180px;
  border-radius: 23px;
  overflow: hidden;
  margin-bottom: 0;

  @media (min-width: ${breakpoints.md}) {
    height: 240px;
  }
  @media (min-width: ${breakpoints.lg}) {
    height: 320px;
  }
  @media (min-width: ${breakpoints.xl}) {
    height: 366px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 37px 22px;
    display: flex;
    align-items: flex-end;
    z-index: 0;

    @media (min-width: ${breakpoints.md}) {
      padding: 36px 60px;
    }

    .text {
      z-index: 1;
    }
  }

  a,
  a:hover {
    color: #161616;
    text-decoration: none;
  }
`
export const Circle = styled.button`
  position: absolute;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  background: #fff;
  outline: none;
  border: none;
  padding: 0;
  &:focus {
    outline: none;
  }
  &.left {
    left: ${(props: SectionProps) => (props.isHome ? '2px' : '22px')};
    justify-content: flex-end;

    @media (min-width: ${breakpoints.md}) {
      left: ${(props: SectionProps) => (props.isHome ? '31px' : '22px')};
    }
    @media (min-width: ${breakpoints.xl}) {
      left: ${(props: SectionProps) => (props.isHome ? '166px' : '22px')};
    }
  }
  &.right {
    right: ${(props: SectionProps) => (props.isHome ? '2px' : '22px')};

    @media (min-width: ${breakpoints.md}) {
      right: ${(props: SectionProps) => (props.isHome ? '31px' : '22px')};
    }
    @media (min-width: ${breakpoints.xl}) {
      right: ${(props: SectionProps) => (props.isHome ? '166px' : '22px')};
    }
  }
`
