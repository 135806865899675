import React from 'react'

import { Link } from 'gatsby'
import PublishedAndReadingTime from 'src/components/PublishedAndReadingTime'
import { Article } from 'src/templates/category'
import { format, parseISO } from 'date-fns'
import pt from 'date-fns/locale/pt-BR'
import categoriesJSON from 'src/assets/data/categories.json'
import subcategoriesJSON from 'src/assets/data/subcategories.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Cards } from './style'

type CardArticleProps = {
  article: Article
}

const CardArticle = ({ article }: CardArticleProps) => {
  const category = categoriesJSON.find((item) => item.id === article.categoryId)
  const subCategory = subcategoriesJSON.find((item) => item.id === article.subcategoryId)
  const [sendDatalayerEvent] = useDataLayer()
  return (
    <Cards className="row py-4 justify-content-center">
      <div className="col-12 col-lg-10  d-flex">
        {article.homeImage && article.homeImage.url && (
          <div className="col-4 pl-0 pr-3">
            <img
              loading="lazy"
              className="rounded-12"
              alt={article.homeImage ? article.homeImage.alt : ''}
              src={article.homeImage ? article.homeImage.url : article.homeImage}
            />
          </div>
        )}
        <div
          className={`${
            article.homeImage && article.homeImage.url
              ? 'col-8 title d-flex align-items-center'
              : 'col-12 title d-flex align-items-center'
          }`}
        >
          <Link
            to={
              !article.isFromInset
                ? `/${category?.slug}/${subCategory?.slug}/${article.slug}`
                : `https://www.inset.com.br/${article.insetCategory}/${article.slug}`
            }
            onClick={() => {
              sendDatalayerEvent({
                section: article.title,
                element_action: 'click button',
                element_name: article.title,
                redirect_url: !article.isFromInset
                  ? `/${category?.slug}/${subCategory?.slug}/${article.slug}`
                  : `https://www.inset.com.br/${article.insetCategory}/${article.slug}`,
              })
            }}
            target={article.isFromInset ? '__blank' : ''}
            rel={article.isFromInset ? 'noreferrer' : ''}
          >
            <div className="d-none d-md-block time">
              <PublishedAndReadingTime
                dataPublicacao={format(parseISO(article.publishedAt), 'dd/LLL', { locale: pt })}
                tempoLeitura={
                  article.readTime ? article.readTime.toString() : article.readingTime.toString()
                }
              />
            </div>
            <span className="fs-12 lh-15 d-block fw-700 sub-category">{article.categoryName}</span>
            <p className="fs-12 lh-15 fs-md-20 lh-md-25 fw-600 text-grayscale--500 text-title">
              {article.title}
            </p>
            <p className="fz-18 lh-22 text-grayscale--500 introduction d-none d-md-block">
              {article.introduction}
            </p>
          </Link>
        </div>
      </div>
    </Cards>
  )
}

export default CardArticle
