import React, { useMemo } from 'react'

import { Link } from 'gatsby'
import ChevronRight from 'inter-frontend-svgs/lib/v2/navigation/chevron-right'
// Style
import { Helmet } from 'react-helmet'

import BreadcrumbStyle from './style'

type BreadcrumbItem = {
  link: string
  text: string
}

type BreadCrumb = {
  path: BreadcrumbItem[]
}

const Breadcrumb = ({ path }: BreadCrumb) => {
  const structuredDataString = useMemo(
    () =>
      JSON.stringify({
        '@context': 'https://schema.org/',
        '@type': 'BreadcrumbList',
        itemListElement: path.map((item: BreadcrumbItem, index: number) => ({
          '@type': 'ListItem',
          position: index + 1,
          name: item.text,
          item: `${process.env.BASE_URL}${item.link}`,
        })),
      }),
    [path],
  )

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{structuredDataString}</script>
      </Helmet>
      <BreadcrumbStyle className="d-flex align-items-center">
        {path.map((breadcrumItem: BreadcrumbItem, index: number) => (
          <span className="lh-18 align-items-center" key={breadcrumItem.text}>
            {index < path.length - 1 ? (
              <Link to={breadcrumItem.link}>{breadcrumItem.text}</Link>
            ) : (
              <span>{breadcrumItem.text}</span>
            )}
            {index < path.length - 1 && (
              <ChevronRight width={17} height={17} color="grayscale--400" />
            )}
          </span>
        ))}
      </BreadcrumbStyle>
    </>
  )
}

export default Breadcrumb
