import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { useTheme } from 'styled-components'

import 'react-multi-carousel/lib/styles.css'
import Carousel, { ArrowProps } from 'react-multi-carousel'
// Components
import OrangeIcon from 'src/components/Icon/OrangeDsIcon'

// Styles
import { Section, Figure, Circle } from './style'

// Types
type imageProps = {
  url: string
  alt: string
}

type DataProps = {
  id: string
  title: string
  description: string
  image: imageProps
  link: string
}

type DataArrayProps = {
  data: DataProps[]
  isHome?: boolean
}

const CarouselNovidades = ({ data, isHome }: DataArrayProps) => {
  const theme = useTheme()
  const [sendDatalayerEvent] = useDataLayer()

  const ArrowRight = ({ onClick }: ArrowProps) => (
    <Circle className="right" onClick={onClick} type="button" isHome={isHome}>
      <OrangeIcon color={theme.colors.primary} icon="chevron-right" size="MD" />
    </Circle>
  )

  const ArrowLeft = ({ onClick }: ArrowProps) => (
    <Circle className="left" onClick={onClick} type="button" isHome={isHome}>
      <OrangeIcon color={theme.colors.primary} icon="chevron-left" size="MD" />
    </Circle>
  )

  const devices = {
    xl: {
      breakpoint: {
        max: 4000,
        min: 1025,
      },
      items: 1,
      partialVisibilityGutter: isHome ? 494 : 250,
    },
    lg: {
      breakpoint: {
        max: 1024,
        min: 769,
      },
      items: 1,
      partialVisibilityGutter: isHome ? 230 : 250,
    },
    md: {
      breakpoint: {
        max: 768,
        min: 577,
      },
      items: 1,
      partialVisibilityGutter: isHome ? 216 : 250,
    },
    xs: {
      breakpoint: {
        max: 576,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: isHome ? 80 : 150,
    },
  }
  return (
    <Section isHome={isHome}>
      <Carousel
        additionalTransfrom={0}
        arrows
        centerMode={false}
        partialVisible
        draggable
        focusOnSelect={false}
        keyBoardControl
        minimumTouchDrag={80}
        responsive={devices}
        customRightArrow={<ArrowRight />}
        customLeftArrow={<ArrowLeft />}
        showDots={false}
        dotListClass="dots"
        renderDotsOutside
        infinite
        swipeable
        itemClass="item pl-md-2 pr-md-2 pl-1 pr-1"
      >
        {data.map((item: DataProps, index: number) => (
          <Figure key={item.title}>
            <a
              href={item.link}
              onClick={() => {
                sendDatalayerEvent({
                  section: item.title,
                  element_action: 'click button',
                  element_name: item.title,
                  redirect_url: item.link,
                  section_name: item.title,
                })
              }}
            >
              <img loading="lazy" src={item.image.url} alt={item.image.alt} />
            </a>
            {!isHome && (
              <a
                href={item.link}
                onClick={() => {
                  sendDatalayerEvent({
                    section: item.title,
                    element_action: 'click button',
                    element_name: item.title,
                    redirect_url: item.link,
                    section_name: item.title,
                  })
                }}
              >
                <figcaption>
                  <div className="text">
                    <span className="text-grayscale--500">#{index + 1}</span>
                    <h4
                      className="fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 fw-400 text-grayscale--500  mb-2"
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                    <p
                      className="fs-14 lh-17 fw-700"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                </figcaption>
              </a>
            )}
          </Figure>
        ))}
      </Carousel>
    </Section>
  )
}
export default CarouselNovidades
