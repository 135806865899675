import styled from 'styled-components'
import { device, breakpoints } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Sora';
  }

  .react-multi-carousel-track {
    padding: 0 !important;
  }

  .button-modal {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    &:hover {
      outline: none;
      background: none;
    }
  }
  .link-modal {
    cursor: pointer;
  }
  .carrossel {
    @media ${device.desktopLG} {
      overflow: visible;
    }
  }
  .show-all {
    background-color: transparent;
    color: #d84315;
  }

  .small-card {
    transition: all 0.2s;
  }

  .small-card-disable {
    max-height: 210px;
    overflow: hidden;

    @media (min-width: ${breakpoints.xl}) {
      max-height: 236px;
    }
  }
  .small-card-active {
    max-height: 1000px;
    overflow: hidden;
  }
  .react-multi-carousel-item {
    padding: 0 6px;
  }
`

export const BlogCarousel = styled.div`
  width: 100%;

  h3 {
    height: 40px;
    overflow: hidden;
  }
  .primary-tag,
  .secundary-tag {
    display: inline;
  }
  &.p-card {
    padding: 0;
    @media ${device.tablet} {
      padding-right: 24px;
    }
    @media ${device.desktopLG} {
      padding-right: 10.5px;
      padding-left: 10.5px;
    }
  }
  article {
    img {
      width: 100%;
      border-radius: 12px;
    }
    div {
      border-bottom: 1px solid #dedfe4;
      min-height: 56px;
      overflow: hidden;
    }
  }
  .react-multi-carousel-track {
    padding: 30px 0 10px !important;
  }
  .title-post {
    font-family: 'Sora';
  }
`
