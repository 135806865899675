import React, { useState, useLayoutEffect } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/useWidth'
import DefaultCarousel from 'src/components/DefaultCarousel'

import OrangeIcon from '../Icon/OrangeDsIcon'
import { SectionWrapper, BlogCarousel } from './style'

type DataProps = {
  image: string
  title: string
  link: string
  videoSlug?: string
  videoTitle?: string
  videoDescription?: string
  videoCategory?: string
  isVideo?: boolean
}
type PortifolioProps = {
  data: {
    news: DataProps[]
    title: string
    subtitle: string
    icon: string
  }
}

const WIDTH_MD = 768

function OInterPortifolio({ data }: PortifolioProps) {
  const [open, setOpen] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState(true)
  const windowWidth = useWidth(300)
  const [sendDatalayerEvent] = useDataLayer()

  useLayoutEffect(() => {
    setIsMobile(windowWidth < WIDTH_MD)
  }, [windowWidth])

  return (
    <SectionWrapper id="dicas-para-investir-melhor" className="bg-white d-flex py-4">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 mb-4">
            <div className="d-flex">
              <OrangeIcon icon={data.icon} color="#D84315" size="LD" />
              <h2 className="fs-20 lh-25 ml-2">{data.title}</h2>
            </div>
            <p>{data.subtitle}</p>
          </div>
          {!isMobile && (
            <div className="col-4 d-flex justify-content-end align-items-center">
              <button
                type="button"
                className="show-all fw-700 fs-14 mr-2"
                onClick={() => {
                  setOpen(!open)
                  sendDatalayerEvent({
                    section: data.title,
                    element_action: 'click button',
                    element_name: 'Exibir tudo',
                    section_name: data.title,
                  })
                }}
              >
                {!open ? 'Exibir tudo' : 'Fechar'}
              </button>
              <OrangeIcon icon="arrow-right" color="#D84315" size="SM" />
            </div>
          )}
          {!isMobile ? (
            <div className={`row small-card ${open ? 'small-card-active' : 'small-card-disable'}`}>
              {data.news.map((item: DataProps) => (
                <div className="col-md-4 col-lg-3" key={item.title}>
                  <BlogCarousel className="mt-md-3">
                    <a
                      href={item.isVideo ? `/aprenda-a-investir/${item.videoSlug}` : item.link}
                      title={item.title}
                      onClick={() => {
                        sendDatalayerEvent({
                          section: item.title,
                          element_action: 'click button',
                          element_name: item.title,
                          section_name: item.title,
                          redirect_url: item.link,
                        })
                      }}
                    >
                      <article className="col-12 px-0">
                        <img loading="lazy" src={item.image} alt={item.title} />
                        <div className="col-12 pt-2 bg-white">
                          <h3 className="fs-16 lh-20 fw-600 mb-3 mt-md-2 mt-xl-2 title-post text-grayscale--500">
                            {item.title}
                          </h3>
                        </div>
                      </article>
                    </a>
                  </BlogCarousel>
                </div>
              ))}
            </div>
          ) : (
            <div className="col-12">
              <DefaultCarousel
                sm={{ items: 2 }}
                md={{ items: 4 }}
                lg={{ items: 4 }}
                xl={{ items: 4 }}
                containerClass="carrossel"
                showDots={false}
                removeArrowOnDeviceType="mobile"
              >
                {data.news.map((item: DataProps) => (
                  <BlogCarousel key={item.title}>
                    <a
                      href={item.isVideo ? `/aprenda-a-investir/${item.videoSlug}` : item.link}
                      title={item.title}
                      onClick={() => {
                        sendDatalayerEvent({
                          section: item.title,
                          element_action: 'click button',
                          element_name: item.link,
                          section_name: item.title,
                          redirect_url: item.link,
                        })
                      }}
                    >
                      <article className="col-12 px-0">
                        <img loading="lazy" src={item.image} alt={item.title} />
                        <div className="col-12 pt-2 bg-white">
                          <h3 className="fs-16 lh-20 fw-600 mb-3 mt-md-2 mt-xl-2 title-post text-grayscale--500">
                            {item.title}
                          </h3>
                        </div>
                      </article>
                    </a>
                  </BlogCarousel>
                ))}
              </DefaultCarousel>
            </div>
          )}
        </div>
      </div>
    </SectionWrapper>
  )
}
export default OInterPortifolio
