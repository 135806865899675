import React from 'react'

import { Link } from 'gatsby'

// Style
import { Container, TabGroup, TabItem } from './style'

type TabItemProps = {
  name: string
  slug: string
}

type SubCategoryTabProps = {
  className?: string
  items: TabItemProps[]
  activeSubTab?: string
  categorySlug: string
}

const SubCategoryTab = ({ className, items, activeSubTab, categorySlug }: SubCategoryTabProps) => (
  <Container className={className}>
    <nav>
      <TabGroup>
        <TabItem active={!activeSubTab}>
          <Link to={`/${categorySlug}`}>Todos</Link>
        </TabItem>
        {items.map((item: TabItemProps) => (
          <TabItem active={activeSubTab === item.slug} key={item.slug}>
            <Link to={`/${item.slug}`}>{item.name}</Link>
          </TabItem>
        ))}
      </TabGroup>
    </nav>
  </Container>
)

SubCategoryTab.defaultProps = {
  className: '',
}

export default SubCategoryTab
