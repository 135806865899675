import React, { useState } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import CardArticle from 'src/components/CardArticle/CardArticle'
import { Article } from 'src/templates/category'
import { Button } from 'src/styles/button'

type ListArticlesType = {
  articles: Article[]
  showCategoryLabel?: boolean
  loading?: boolean
  size?: number
  loadMore?: () => null
}
const ListArticles = ({
  articles,
  showCategoryLabel,
  size,
  loadMore,
  loading,
}: ListArticlesType) => {
  const [limit, setLimit] = useState(8)
  const [sendDatalayerEvent] = useDataLayer()

  return (
    <section id="artigos">
      <div className="container">
        {Object.values(articles)
          .slice(0, size || limit)
          .map((article: Article) => (
            <CardArticle
              key={article.id}
              article={article}
              showCategoryLabel={showCategoryLabel || false}
            />
          ))}
      </div>
      {Object.values(articles).length > limit && (
        <div className="container pt-3 pb-5">
          <div className="row mx-0">
            <Button
              disabled={loading}
              onClick={() => {
                sendDatalayerEvent({
                  section: '',
                  element_action: 'click button',
                  element_name: 'Carregar mais',
                })
                if (loadMore) {
                  loadMore()
                  return false
                }
                return setLimit(limit + 8)
              }}
              type="submit"
              className="max"
            >
              Carregar mais
            </Button>
          </div>
        </div>
      )}
    </section>
  )
}

export default ListArticles
