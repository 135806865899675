import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { borderRounded } from 'src/styles/radius'
import breakpoints from 'src/styles/breakpoints'

export const NewsContainer = styled.div`
  hr {
    margin: 0;
    background: #dedfe4;
  }
`

export const CardNews = styled.div`
  padding: 1rem 0rem 1.5rem 0rem;
  border-bottom: 1px solid ${grayscale[200]};
  font-family: 'Sora';

  @media (min-width: ${breakpoints.md}) {
    min-height: 136px;
  }

  .latest-news {
    &__order {
      color: #c5c5c6;
    }
    &__image {
      align-self: center;
      width: 100%;

      img {
        border-radius: ${borderRounded[12]};
      }
    }
    &__category {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

export const Number = styled.h3`
  font-family: Sora;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.primary};

  @media (max-width: ${breakpoints.xl}) and (min-width: ${breakpoints.md}) {
    font-size: 20px;
    line-height: 25px;
  }
`
export const ArrowContainer = styled.button`
  position: absolute;
  align-items: center;
  border-radius: 50%;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  top: 0;

  &:focus {
    outline: none;
  }
  &.left {
    left: 0;
    justify-content: flex-end;
  }
  &.right {
    right: 0;
  }
`
export const Content = styled.div`
  padding: 0 29px 16px;
  min-height: 108px;

  p {
    height: 78px;
    overflow: hidden;
  }

  a:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 0 0 16px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-bottom: 32px;
  }
`
