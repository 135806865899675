import React, { useState, useEffect } from 'react'

import axios from 'axios'
import recomendados from 'src/assets/data/recomendados.json'

// Components
import Icon from '../Icon/index'
// Styles
import { Container } from './style'

type Props = {
  title: string
}

type QuotationProps = {
  papel: string
  tipo: string
  variacao: string
  preco: string
}

function numberColor(number: number) {
  if (number > 0) {
    return 'success'
  }
  if (number < 0) {
    return 'error'
  }
  return 'neutral'
}

const QuotesEconomyIndices = ({ title }: Props) => {
  const [economicQuotes, setEconomicQuotes] = useState([])
  const date = new Date()
  const setDate = date.toLocaleDateString()

  async function dataLoad() {
    try {
      const response = await axios.get(`${process.env.QUOTES_AND_INDICES}`)
      switch (title) {
        case 'Maiores Altas':
          setEconomicQuotes(response.data.destaques[0].items)
          break
        case 'Maiores Baixas':
          setEconomicQuotes(response.data.destaques[1].items)
          break
        case 'Recomendados Inter Research':
          setEconomicQuotes(recomendados.items)
          break
        default:
          setEconomicQuotes(response.data.destaques[0].items)
          break
      }
    } catch (error) {
      setEconomicQuotes([])
    }
  }

  function setKey(index: number) {
    return index
  }

  useEffect(() => {
    dataLoad()
  }, [])

  return (
    <div className="col-12">
      <Container className="col-12">
        <h3 className="fs-16 lh-19 fw-600 text-grayscale--500 pb-2 mb-4">{title}</h3>
        {economicQuotes.map((item: QuotationProps, index: number) => (
          <div
            key={setKey(index)}
            className="px-0 align-items-center justify-content-between border-list"
          >
            <div className="col-12 d-flex align-items-center justify-content-between px-0">
              <strong className="fs-12 lh-14">{item.papel}</strong>
              <strong className="fs-12 lh-14">{item.preco}</strong>
            </div>
            <div className="col-12 d-flex align-items-center justify-content-between px-0">
              <div>
                <Icon
                  width={12}
                  height={12}
                  directory="v2"
                  icon="action/time"
                  color="newgray--base"
                />
                <span className="fs-10 lh-12 fw-600 text-grayscale--400 ml-2">
                  {setDate} / Moeda
                </span>
              </div>
              <div>
                <span
                  className={`fs-10 lh-12 fw-600 mr-2 ${numberColor(
                    Number(item.variacao.replace(',', '.').replace('%', '')),
                  )}`}
                >
                  ({item.variacao})
                </span>
              </div>
            </div>
          </div>
        ))}
      </Container>
    </div>
  )
}

export default QuotesEconomyIndices
