import React, { useState, useLayoutEffect, useRef, ReactNode, MutableRefObject } from 'react'

import { useTheme } from 'styled-components'
import Carousel from 'react-multi-carousel'
// Components
import useWidth from 'src/hooks/useWidth'

import RightArrow from './RightArrow'
import LeftArrow from './LeftArrow'
// styles
import 'react-multi-carousel/lib/styles.css'
import { Container } from './style'

type DefaultCarouselProps = {
  children: ReactNode[]
  sm: {
    items: number
    slidesToSlide?: number
    partialVisibilityGutter?: number
  }
  md: {
    items: number
    slidesToSlide?: number
    partialVisibilityGutter?: number
  }
  lg: {
    items: number
    slidesToSlide?: number
    partialVisibilityGutter?: number
  }
  xl: {
    items: number
    slidesToSlide?: number
    partialVisibilityGutter?: number
  }
  removeArrowOnDeviceType?: string[]
  containerClass?: string
  customRightArrow?: Node
  customLeftArrow?: Node
  beforeChange?: () => Record<string, unknown>
  ssr?: boolean
  showDots?: boolean
  itemClass?: string
}

const WIDTH_MD = 768
const WIDTH_LG = 992
const WIDTH_XL = 1200

function DefaultCarousel({
  children,
  sm,
  md,
  lg,
  xl,
  removeArrowOnDeviceType,
  containerClass,
  customRightArrow,
  customLeftArrow,
  beforeChange,
  ssr,
  showDots,
  itemClass,
}: DefaultCarouselProps) {
  const CarouselRef = useRef<MutableRefObject<Carousel | unknown>>()
  const windowWidth = useWidth(300)
  const [breakpoint, setBreakpoint] = useState(sm)
  const CustomRightArrow = customRightArrow
  const CustomLeftArrow = customLeftArrow
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: xl.items,
      slidesToSlide: xl.slidesToSlide,
      partialVisibilityGutter: xl.partialVisibilityGutter,
    },
    desktop: {
      breakpoint: { max: 1199, min: 992 },
      items: lg.items,
      slidesToSlide: lg.slidesToSlide,
      partialVisibilityGutter: lg.partialVisibilityGutter,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: md.items,
      slidesToSlide: md.slidesToSlide,
      partialVisibilityGutter: md.partialVisibilityGutter,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: sm.items,
      slidesToSlide: sm.slidesToSlide,
      partialVisibilityGutter: sm.partialVisibilityGutter,
    },
  }

  const theme = useTheme()

  const isShowDots = () => {
    if (showDots === false) return showDots
    if (children.length <= breakpoint.items) {
      return false
    }
    return true
  }

  useLayoutEffect(() => {
    if (windowWidth >= WIDTH_XL) {
      setBreakpoint(xl)
    } else if (windowWidth >= WIDTH_LG && windowWidth < WIDTH_XL) {
      setBreakpoint(lg)
    } else if (windowWidth >= WIDTH_MD && windowWidth < WIDTH_LG) {
      setBreakpoint(md)
    } else {
      setBreakpoint(sm)
    }

    if (CarouselRef.current) {
      CarouselRef.current.goToSlide(0)
    }
  }, [lg, md, sm, windowWidth, xl])

  return (
    <Container color={theme.colors.primary}>
      <Carousel
        ref={CarouselRef}
        responsive={responsive}
        customRightArrow={
          CustomRightArrow ? <CustomRightArrow /> : <RightArrow color={theme.colors.primary} />
        }
        customLeftArrow={
          CustomLeftArrow ? <CustomLeftArrow /> : <LeftArrow color={theme.colors.primary} />
        }
        showDots={isShowDots()}
        partialVisible
        removeArrowOnDeviceType={removeArrowOnDeviceType}
        containerClass={containerClass}
        beforeChange={beforeChange}
        itemClass={itemClass}
        ssr={ssr}
      >
        {children}
      </Carousel>
    </Container>
  )
}

function returnDefaultProps() {
  return {
    sm: {
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 0,
    },
    md: {
      items: 2,
      slidesToSlide: 1,
      partialVisibilityGutter: 0,
    },
    lg: {
      items: 3,
      slidesToSlide: 1,
      partialVisibilityGutter: 0,
    },
    xl: {
      items: 4,
      slidesToSlide: 1,
      partialVisibilityGutter: 0,
    },
  }
}

DefaultCarousel.defaultProps = returnDefaultProps()

export default DefaultCarousel
