import React from 'react'

// data
import articles from 'src/assets/data/articles.json'
// components
import { News } from 'src/components/News'
import CategoryTab from 'src/components/Tabs/CategoryTab/index'
// service
import { NewsField } from 'src/services/articles/articles'
// type
import { Articles } from 'src/types/Articles'

const tabsItems = [
  {
    name: 'Equity Research',
    unformated: 'equity-research',
    id: 1,
  },
  {
    name: 'Macro Research',
    unformated: 'macro-research',
    id: 2,
  },
  {
    name: 'Inter Strategy',
    unformated: 'inter-strategy',
    id: 3,
  },
  {
    name: 'Fundos Imobiliários',
    unformated: 'fundos-imobiliarios',
    id: 4,
  },
]

const Analysis = () => {
  const analysisArticles = articles.analise.filter((article: Articles) => article)

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <CategoryTab items={tabsItems.map((item) => item)} isSearchable={false}>
            {tabsItems.map((item) => (
              <section>
                <News
                  noticias={analysisArticles
                    .filter((article) => article.subCategorySlug === item.unformated)
                    .map((item) => NewsField(item))}
                  title="Últimas notícias"
                />
              </section>
            ))}
          </CategoryTab>
        </div>
      </div>
    </div>
  )
}

export default Analysis
