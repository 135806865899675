import styled from 'styled-components'

import { gray, white, linear } from './colors'
import { borderRounded } from './radius'

export const Button = styled.button`
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.primary};
  border-radius: ${borderRounded['8']};
  border: 0;
  color: ${white};
  display: flex;
  font-weight: bold;
  font-size: 10px;
  height: 29px;
  line-height: 17px;
  padding: 0 16px;
  text-align: center;
  width: 101px;

  &:hover {
    background: ${(props) => props.theme.colors.primary};
    opacity: 0.9;
  }

  &:disabled {
    background-color: ${gray[200]};
    opacity: 1;
  }

  &.gradient {
    background: ${linear.orange};
  }

  &.inverse {
    background: ${white};
    color: ${(props) => props.theme.colors.primary};
    border: 1px solid ${(props) => props.theme.colors.primary};
  }

  &.max {
    width: 100%;
    max-width: 100%;
    height: 48px;
    font-size: 14px;
  }
`
