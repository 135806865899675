import styled from 'styled-components'

type bgprops = {
  bg: string
}

export const SectionWrapper = styled.section`
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Sora';
  }

  .button-modal {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    &:hover {
      outline: none;
      background: none;
    }
  }
  .link-modal {
    cursor: pointer;
  }

  .show-all {
    background-color: transparent;
    color: #d84315;
  }

  .small-card {
    max-height: 260px;
    overflow: hidden;
  }
  .small-card-active {
    max-height: 1000px;
    overflow: hidden;
    transition: max-height 3s;
  }
`

export const BlogCarousel = styled.div`
  padding: 0 12px;

  .react-multi-carousel-track {
    padding: 0 !important;
  }
`
export const CardVideo = styled.figure`
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
    url(${(props: bgprops) => props.bg});
  background-repeat: no-repeat;
  background-size: 100%;
  min-width: 100%;
  min-height: 292px;
  position: relative;
  border-radius: 16px;
  display: flex;
  align-items: flex-end;
  padding: 16px;

  figcaption {
    width: 100%;

    color: #d84315;
  }
`
export const Player = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #16161624;
  border: 1px solid #ffffff24;
  border-radius: 50%;
  position: absolute;
  top: 96px;
  left: 50%;
  transform: translateX(-50%);
`
