import React from 'react'

import { ArrowProps } from 'react-multi-carousel'
import OrangeDsIcon from 'src/components/Icon/OrangeDsIcon'

type ColorProp = {
  color: string
}

function RightArrow({ onClick, color }: ArrowProps & ColorProp) {
  return (
    <button
      onClick={onClick}
      type="button"
      className="border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
    >
      <OrangeDsIcon size="LD" color={color} icon="arrow-right" />
    </button>
  )
}

export default RightArrow
