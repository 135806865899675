import React, { ChangeEvent, useRef, useState } from 'react'

import { navigate } from 'gatsby'

// Style
import GeneralSearchStyle from './style'

type GeneralSearchProps = {
  initialValue?: string
  noContainerBackground: boolean
  styles?: string
}

const GeneralSearch = ({ initialValue, noContainerBackground, styles }: GeneralSearchProps) => {
  const textInput = useRef(null)
  const [searchText, setSearchText] = useState(initialValue)

  function handleChange(evt: ChangeEvent<HTMLInputElement>) {
    const valueInput = evt.target.value
    setSearchText(valueInput)
    navigate(`/search?q=${encodeURIComponent(valueInput)}`)
  }

  return (
    <GeneralSearchStyle
      id="search-form"
      noContainerBackground={noContainerBackground}
      className={styles}
    >
      <input
        type="text"
        placeholder="O que você está procurando? Busque aqui!"
        className="px-3 text-center fs-16 fs-sm-14 lh-17"
        maxLength={150}
        onChange={handleChange}
        value={searchText}
        ref={textInput}
      />
    </GeneralSearchStyle>
  )
}

function returnDefaultProps() {
  return {
    initialValue: '',
    noContainerBackground: false,
    styles: '',
  }
}

GeneralSearch.defaultProps = returnDefaultProps()

export default GeneralSearch
