import styled, { css } from 'styled-components'
import { grayscale, orange, white } from 'src/styles/colors'

import breakpoints from '../../../styles/breakpoints'

export const Container = styled.div`
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
`

export const TabGroup = styled.ul`
  margin-bottom: 0;
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  overflow-x: auto;

  @media (min-width: ${breakpoints.md}) {
    overflow-x: visible;
  }
`

type TabItemStyleProps = {
  active: boolean
}

export const TabItem = styled.li<TabItemStyleProps>`
  margin: 0 12px;
  flex-shrink: 0;

  > a {
    align-items: center;
    font-size: 12px;
    border-radius: 20px;
    border: 1px solid ${grayscale[200]};
    background: ${white};
    color: ${grayscale[500]};
    display: flex;
    font-weight: normal;
    justify-content: center;
    padding: 10px 15px;
    text-decoration: none;

    &:hover {
      background-color: ${grayscale[100]};
      color: ${grayscale[500]};
    }

    ${(props: TabItemStyleProps) =>
      props.active &&
      css`
        background-color: ${orange.base};
        border: 1px solid ${orange.base};
        color: ${white};

        &:hover {
          background-color: ${orange.base};
          color: ${white};
        }
      `}

    &:focus {
      outline: 0px;
    }
  }
`
