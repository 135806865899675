import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'
import { device } from 'src/styles/breakpoints'

export const Cards = styled.div`
  /* border-bottom: 1px solid #dedfe4; */
  img {
    width: 100%;
    min-height: 80px;
    object-fit: cover;
  }
  @media ${device.tablet} {
    img {
      min-height: 160px;
    }
  }
  &:last-child {
    border-bottom: 0;
  }

  .title {
    padding-left: 0;

    @media (min-width: ${breakpoints.md}) {
      padding-left: 24px;
    }
  }

  .time {
    margin-bottom: 24px;
  }
  .sub-category,
  .text-title,
  .introduction {
    margin-bottom: 10px;

    @media (min-width: ${breakpoints.md}) {
      margin-bottom: 8px;
    }
  }
`

export const ThemeLabel = styled.p`
  color: ${(props) => props.primary};
`
