import React, { ComponentClass, FunctionComponent, useMemo } from 'react'

import Loadable, { LoadableComponent } from 'react-loadable'

import LoadingIndicator from '../LoadingIndicator'

type Icon = {
  width: string | number
  height: string | number
  rotate: string | number
  icon: string
  color: string
  directory: string
  className: string
}

function Icon({ width, height, icon, color, rotate, directory, className }: Icon) {
  const SvgIcon:
    | (ComponentClass<Icon> & LoadableComponent)
    | (FunctionComponent<Icon> & LoadableComponent) = useMemo(
    () =>
      Loadable({
        loader: () => import(`inter-frontend-svgs/lib/${directory}/${icon}`),
        loading: () => <LoadingIndicator size="small" />,
      }),
    [directory, icon],
  )

  return (
    <SvgIcon
      width={width}
      height={height}
      icon={icon}
      color={color}
      rotate={rotate}
      directory={directory}
      className={className}
    />
  )
}

function returnDefaultProps() {
  return {
    width: '',
    height: '',
    icon: '',
    color: 'orange--base',
    rotate: 0,
    directory: '',
    className: '',
  }
}

Icon.defaultProps = returnDefaultProps()

export default Icon
