import styled from 'styled-components'
import { grayscale, white } from 'src/styles/colors'
import { borderRounded } from 'src/styles/radius'
import breakpoints from 'src/styles/breakpoints'
// Assets
import iconSearch from 'src/assets/images/icons/icon-search.svg'

interface IMenu {
  noContainerBackground: boolean
}

export default styled.div`
  padding: 20px;
  z-index: 1;

  ${(props: IMenu) =>
    props.noContainerBackground
      ? ''
      : `
    background: ${grayscale['100']};
  `}

  @media(min-width: ${breakpoints.lg}) {
    margin-top: 35px;
    position: relative;
    right: 0;
    left: 0;
    z-index: 1;
  }

  input {
    border: none;
    border-radius: ${borderRounded['8']};
    color: ${grayscale['500']};
    font-weight: 600;
    height: 44px;
    outline: 0;
    width: 100%;
    max-width: 890px;
    margin: 0 auto;
    display: block;

    &:placeholder-shown {
      background: ${white} url(${iconSearch}) no-repeat;
      background-position: calc(50% - 184px);
      color: ${grayscale['300']};
      font-weight: 300;
      font-size: 14px;
    }
  }
`
