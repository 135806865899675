import React, { forwardRef, ForwardedRef } from 'react'

import { LoadingIndicator } from './style'

type LoadingIndicatorProps = {
  className?: string
  size?: string
}

const LoadingIndicatorComponent = forwardRef(
  ({ className, size }: LoadingIndicatorProps, ref: ForwardedRef<SVGSVGElement>) => (
    <LoadingIndicator
      className={className}
      ref={ref}
      size={size}
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <path
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        fill="none"
      />
    </LoadingIndicator>
  ),
)

LoadingIndicatorComponent.defaultProps = {
  size: 'default',
}

export default LoadingIndicatorComponent
