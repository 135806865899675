import React, { useState, useLayoutEffect } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import Carousel, { ArrowProps } from 'react-multi-carousel'
import IconOrangeDs from 'src/components/Icon/OrangeDsIcon'
import useWidth from 'src/hooks/useWidth'
import { useTheme } from 'styled-components'

import { NewsContainer, Number, ArrowContainer, Content } from './style'

export type NoticiaType = {
  categorySlug: string
  categoria: string
  titulo?: string
  subTitle: string
  image?: string
  slug: string
  link: string
}
type NoticiasType = {
  noticias: NoticiaType[]
  title: string
}

const WIDTH_MD = 768
const WIDTH_LG = 1024

export const News = ({ noticias, title }: NoticiasType) => {
  const theme = useTheme()
  const [isMobile, setIsMobile] = useState(true)
  const [isTabletLandscape, setIsTabletLandscape] = useState(true)
  const [sendDatalayerEvent] = useDataLayer()

  const windowWidth = useWidth(300)

  function handleIndex(index: number) {
    return index
  }

  useLayoutEffect(() => {
    setIsMobile(windowWidth < WIDTH_MD)
    setIsTabletLandscape(windowWidth < WIDTH_LG)
  }, [windowWidth])

  const ArrowRight = ({ onClick }: ArrowProps) => (
    <ArrowContainer className="right" onClick={onClick} type="button">
      <IconOrangeDs color={theme.colors.primary} icon="chevron-right" size="LD" />
    </ArrowContainer>
  )

  const ArrowLeft = ({ onClick }: ArrowProps) => (
    <ArrowContainer className="left" onClick={onClick} type="button">
      <IconOrangeDs color={theme.colors.primary} icon="chevron-left" size="LD" />
    </ArrowContainer>
  )

  const devices = {
    xs: {
      breakpoint: {
        max: 576,
        min: 0,
      },
      items: 1,
    },
  }

  return (
    <NewsContainer className="container">
      <div className="row">
        <div className="col-12 d-none mb-4 d-md-block">
          <h2 className="fs-16 lh-19 fw-600 text-grayscale--500">{title}</h2>
        </div>
        <div className="col-12">
          <div className="row">
            {isMobile ? (
              <div className="col-12">
                <Carousel
                  additionalTransfrom={0}
                  arrows
                  centerMode={false}
                  partialVisible
                  draggable
                  focusOnSelect={false}
                  keyBoardControl
                  minimumTouchDrag={80}
                  responsive={devices}
                  customRightArrow={<ArrowRight />}
                  customLeftArrow={<ArrowLeft />}
                  showDots={false}
                  dotListClass="dots"
                  renderDotsOutside
                  infinite
                  swipeable
                  itemClass="item pl-md-2 pr-md-2 pl-1 pr-1"
                >
                  {noticias.map((item: NoticiaType, index: number) => (
                    <div key={handleIndex(index)}>
                      <Content>
                        <a
                          href={item.link}
                          title={item.subTitle}
                          className="text-grayscale--500"
                          onClick={() => {
                            sendDatalayerEvent({
                              section: 'Últimas notícias',
                              element_action: 'click button',
                              element_name: item.titulo,
                              redirect_url: item.link,
                            })
                          }}
                        >
                          <div className="row d-flex align-items-center">
                            <div className="col-2">
                              <Number>0{index + 1}</Number>
                            </div>
                            <div className="col-10">
                              <p className="fs-14 lh-16 m-0">{item.subTitle}</p>
                            </div>
                          </div>
                        </a>
                      </Content>
                      <hr />
                    </div>
                  ))}
                </Carousel>
              </div>
            ) : (
              <>
                {noticias
                  .slice(0, !isTabletLandscape ? 4 : 3)
                  .map((item: NoticiaType, index: number) => (
                    <div
                      className={`${!isTabletLandscape ? 'col-3' : 'col-4'}`}
                      key={handleIndex(index)}
                    >
                      <Content>
                        <a
                          href={`/${item.link}`}
                          title={item.subTitle}
                          className="text-grayscale--500"
                          onClick={() => {
                            sendDatalayerEvent({
                              section: 'Últimas notícias',
                              element_action: 'click button',
                              element_name: item.titulo,
                              redirect_url: item.link,
                            })
                          }}
                        >
                          <div className="row">
                            <div className="col-2">
                              <Number>0{index + 1}</Number>
                            </div>
                            <div className="col-10">
                              <p className="fs-md-12 lh-md-15 fs-lg-14 lh-lg-17 fs-xl-16 lh-xl-19 m-0">
                                {item.subTitle}
                              </p>
                            </div>
                          </div>
                        </a>
                      </Content>
                      <hr />
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
    </NewsContainer>
  )
}
