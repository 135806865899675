/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect, useRef, useState } from 'react'

import { Link } from 'gatsby'
import ScrollContainer from 'react-indiana-drag-scroll'
import ChevronLeft from 'inter-frontend-svgs/lib/v2/navigation/chevron-left'
import ChevronRight from 'inter-frontend-svgs/lib/v2/navigation/chevron-right'
// Components
import GeneralSearch from 'src/components/GeneralSearch'
import ExitIcon from 'src/assets/images/icons/icon-exit.svg'
import SearchIcon from 'src/assets/images/icons/icon-search.svg'

import { TabGroup, TabItem, TabScrollButton, SearchButton } from './style'
import SubCategoryTab from '../SubCategoryTab'

type TabItemProps = {
  id?: number
  name: string
  slug?: string
  subcategories?: {
    id: number
    name: string
    slug: string
  }[]
}

type ScrollDistanceProps = {
  [param: string]: number
  right: number
  left: number
}

type CategoryTabProps = {
  activeSubTab?: string
  items: TabItemProps[]
  isSearchable?: boolean
  children: React.ReactNode[]
}

const CategoryTab = ({ activeSubTab, items, isSearchable = true, children }: CategoryTabProps) => {
  const scrollContainerRef = useRef<HTMLElement>(null)
  const tabGroupRef = useRef<HTMLUListElement>(null)
  const scrollDistance: ScrollDistanceProps = { right: 200, left: -200 }
  const [showArrows, setShowArrows] = useState(false)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [search, setSearch] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    if (scrollContainerRef.current && tabGroupRef.current) {
      setShowArrows(scrollContainerRef.current.offsetWidth < tabGroupRef.current.offsetWidth)
    }
  }, [scrollContainerRef, tabGroupRef])

  function handleScroll(scrollleft: number) {
    setScrollLeft(scrollleft)
  }

  function handleClickScroll(direction: string) {
    if (scrollContainerRef.current) {
      const newScrollLeft = scrollLeft + scrollDistance[direction]
      setScrollLeft(newScrollLeft)
      scrollContainerRef.current.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      })
    }
  }

  function handleIndex(index: number) {
    return index
  }

  return (
    <div>
      {search && <GeneralSearch styles="position-absolute mt-5" />}
      <div className={`position-relative w-100 mb-5 ${search ? 'pb-5' : ''}`}>
        {showArrows && (
          <>
            <TabScrollButton onClick={() => handleClickScroll('left')} direction="left">
              <ChevronLeft widht={24} height={24} />
            </TabScrollButton>
            <TabScrollButton onClick={() => handleClickScroll('right')} direction="right">
              <ChevronRight widht={24} height={24} />
            </TabScrollButton>
          </>
        )}
        <ScrollContainer
          innerRef={scrollContainerRef}
          component="nav"
          onScroll={() => handleScroll}
        >
          <TabGroup ref={tabGroupRef}>
            {items.map((item: TabItemProps, index: number) => (
              <TabItem active={activeTab === index} key={item.id}>
                {item.slug ? <Link to={`/${item.slug}`}>{item.name}</Link> : ''}
                {!item.slug ? (
                  <div
                    role="none"
                    className="tab-item"
                    onClick={() => setActiveTab(index)}
                    onKeyPress={(event) => (event.key === 'Enter' ? setActiveTab(item.name) : '')}
                  >
                    {item.name}
                  </div>
                ) : (
                  ''
                )}
                {activeTab === item.slug && item.subcategories && (
                  <SubCategoryTab
                    categorySlug={item.slug}
                    items={item.subcategories}
                    activeSubTab={activeSubTab}
                    className="position-absolute"
                  />
                )}
              </TabItem>
            ))}
          </TabGroup>
        </ScrollContainer>

        {isSearchable ? (
          <SearchButton onClick={() => setSearch(!search)}>
            {search ? (
              <img src={ExitIcon} alt="Fechar" title="Fechar" />
            ) : (
              <img src={SearchIcon} alt="Buscar" title="Buscar 2" />
            )}
          </SearchButton>
        ) : (
          ''
        )}
      </div>
      <div>
        {children.map((item: React.ReactNode, index: number) => (
          <div className={`${activeTab === index ? 'd-block' : 'd-none'}`} key={handleIndex(index)}>
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CategoryTab
